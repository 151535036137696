/** @jsx jsx */
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { jsx } from "theme-ui";
import styled from "@emotion/styled";
// TODO: ensure the alt tags for the image elements abide by the best practices for SEO purposes

export const FulfilledMan = () => {
  const image = Data();
  return (
    <Img
      sx={{
        height: `full`,
        transform: `scaleX(-1)`,
      }}
      fluid={image.furkanDemir.childImageSharp.fluid}
      alt=" A fulfilled middle aged man."
    />
  );
};
export const HappyMan = () => {
  const image = Data();
  return (
    <Img
      sx={{
        alignSelf: `center`,
        height: [350, 350, 550, 850, 1000],
      }}
      fluid={image.happyMan.childImageSharp.fluid}
      alt="A man smiling with his heart"
    />
  );
};

export const SandStone = (props) => {
  const image = Data();
  return (
    <Img
    {...props}
      sx={{
        height: [`50rem`, `50rem`, `61rem`, `71rem`, `51rem`],
        transform: `rotate(180deg)`,
      }}
      fluid={image.sandStone.childImageSharp.fluid}
      alt="Earth Landing Hero"
    />
  );
};

export const MarsLogoWhite = () => {
  const image = Data();
  return (
    <Img
      fluid={image.iconLogoWhite.childImageSharp.fluid}
      alt="The Mars Contract Icon in white"
    />
  );
};

export const MarsTextLogoWhite = () => {
  const image = Data();
  return (
    <Img
      fluid={image.textLogoWhite.childImageSharp.fluid}
      alt="The Mars Contract Text Logo in White"
    />
  );
};

export const CTAWhite = () => {
  const image = Data();
  return (
    <Img
      fluid={image.ctaWhite.childImageSharp.fluid}
      alt="Click here to book a call wth the founder of The Mars Contract through Calendly"
      sx={{
        transform: `translateY(5px)`,
        transition: `.5s`,
        ":hover": {
          transform: `translateY(-5px)`,
          transition: `.5s`,
        },
      }}
    />
  );
};
export const CTABurnt = () => {
  const image = Data();
  return (
    <Img
      fluid={image.ctaBurnt.childImageSharp.fluid}
      alt="Click here to Book a call wth the founder of The Mars Contract through Calendly"
      sx={{
        transform: `translateY(5px)`,
        transition: `.5s`,
        ":hover": {
          transform: `translateY(-5px)`,
          transition: `.5s`,
        },
      }}
    />
  );
};

export const LogoWordMark = () => {
  const image = Data();
  return (
    <Img
      fluid={image.logoAndWordMark.childImageSharp.fluid}
      alt="The Mars Contract wordmark with icon"
    />
  );
};
export const WordMark = () => {
  const image = Data();
  return (
    <Img
      fluid={image.wordMark.childImageSharp.fluid}
      alt="The Mars Contract Wordmark"
    />
  );
};
export const LogoWordMarkFlat = () => {
  const image = Data();
  return (
    <Img
      fluid={image.logoAndWordMarkFlat.childImageSharp.fluid}
      alt="Mars Contract logo and wordmark"
    />
  );
};
export const Alexas = () => {
  const image = Data();
  return (
    <StyledBackgroundLanding
      fluid={image.alexisFotos.childImageSharp.fluid}
      alt="Sovereign"
      sx={{
        transform: `scaleX(-1)`,
      }}
    />
  );
};
export const Dainis = () => {
  const image = Data();
  return (
    <StyledBackgroundLanding
      fluid={image.dainisGraveris.childImageSharp.fluid}
      alt="Sex"
    />
  );
};
export const Jeremy = () => {
  const image = Data();
  return (
    <StyledBackgroundLanding
      fluid={image.jeremyBishop.childImageSharp.fluid}
      alt="Self"
    />
  );
};

export const LyonsMobile = () => {
  const image = Data();
  return (
    <Img
      fluid={image.founderImage.childImageSharp.fluid}
      alt="A Portrait of the Mars Contract Founder"
    />
  );
};
// Fluid sharp queries, I seperated them so we could query multiple files at once
//! using this query to bring in the stock images
export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(fit: CONTAIN, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

// this is a query to size images a specifi way. We can pplay around with this to get the right fit for the incoming image
//! using this query to bring in the icon pngs
export const fluidImageIcon = graphql`
  fragment fluidImageIcon on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`;
// querying the images from the source files using graphql and usestaticquery hook
export const Data = () => {
  const landingImages = useStaticQuery(
    graphql`
      query marsImageQuery {
        furkanDemir: file(relativePath: { eq: "furkan-demir-crop.jpg" }) {
          ...fluidImage
        }
        happyMan: file(relativePath: { eq: "happy_man.jpg" }) {
          ...fluidImage
        }
        sandStone: file(relativePath: { eq: "sand_stone.jpg" }) {
          ...fluidImage
        }
        iconLogoWhite: file(relativePath: { eq: "MARSASSETS-30.png" }) {
          ...fluidImageIcon
        }
        textLogoWhite: file(relativePath: { eq: "MARSASSETS-33.png" }) {
          ...fluidImageIcon
        }
        logoAndWordMark: file(relativePath: { eq: "Logomark + Workmark.png" }) {
          ...fluidImageIcon
        }
        logoAndWordMarkFlat: file(
          relativePath: { eq: "logowordmarkflat.png" }
        ) {
          ...fluidImageIcon
        }
        wordMark: file(relativePath: { eq: "Wordmark.png" }) {
          ...fluidImageIcon
        }
        ctaWhite: file(relativePath: { eq: "tmc_cta_white.png" }) {
          ...fluidImage
        }
        ctaBurnt: file(
          relativePath: { eq: "MARS_CTAJ_Half Circle Solar.png" }
        ) {
          ...fluidImage
        }
        jeremyBishop: file(relativePath: { eq: "jeremy-bishop.jpg" }) {
          ...fluidImage
        }
        alexisFotos: file(relativePath: { eq: "alexas-fotos.jpg" }) {
          ...fluidImage
        }
        dainisGraveris: file(relativePath: { eq: "dainis-graveris.jpg" }) {
          ...fluidImage
        }
        founderImage: file(relativePath: { eq: "FOUNDER.png" }) {
          ...fluidImage
        }
      }
    `
  );
  return landingImages;
};

//////////////
//Components//
//////////////

export const StyledBackgroundLanding = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  @media (max-width: 1199px) {
    width: 100%;
    height: 700px;
  }
  @media (max-width: 899px) {
    width: 100%;
    height: 900px;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: 600px;
  }
  @media (max-width: 400px) {
    width: 100%;
    height: 800px;
  }
`;
